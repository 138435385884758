import { isArray, isObject } from '@/utils'

export const getValueNum = (filterData, hiddens) => {
  let num = 0
  Object.keys(filterData).forEach(key => {
    if (/\-noValue$/.test(key) || hiddens[key]) return
    if (typeof filterData[key] !== 'object' && filterData[key] !== '') {
      console
      num += 1
    } else if (isArray(filterData[key]) && JSON.stringify(filterData[key]) !== '[]') {
      num += 1
    } else if (isObject(filterData[key]) && JSON.stringify(filterData[key]) !== '{}') {
      num += 1
    }
  })
  return num
}
