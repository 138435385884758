<template>
  <div
    class="range-date"
    @click.stop="() => {}"
  >
    <div class="range-date-container">
      <div
        class="range-date-value"
        :class="{'is-value': value && value[1]}"
        @click="clickFn"
      >
        {{ value && value[0] ? value[0] : '开始日期' }}
      </div>
      <div class="range-date-text">至</div>
      <div
        class="range-date-value"
        :class="{'is-value': value && value[1]}"
        @click="clickFn"
      >
        {{ value && value[1] ? value[1] : '结束日期' }}
      </div>
    </div>
    <van-popup
      v-model="showPicker"
      round
      class="filter-model-range-date"
      position="bottom"
      :get-container="'body'"
      :close-on-click-overlay="false"
      @click.native.stop="() => {}"
      @close="onClose"
    >
      <div class="range-date-pick-title">
        <div
          class="cancel"
          @click="showPicker = false"
        >取消</div>
        <div class="title">选择时间</div>
        <div
          class="confirm"
          @click="onConfirm([startDate, endDate])"
        >确定</div>
      </div>
      <div class="range-date-pick-container">
        <van-datetime-picker
          v-model="startDate"
          class="left"
          :type="dateType"
          title="选择年月日"
          :show-toolbar="false"
          :min-date="minDate"
          :max-date="maxDate"
        />
        <div class="center">至</div>
        <van-datetime-picker
          v-model="endDate"
          class="right"
          :type="dateType"
          title="选择年月日"
          :show-toolbar="false"
          :min-date="endMinDate"
          :max-date="maxDate"
        />
      </div>
    </van-popup>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import commMixin from '../../mixins/comm'

export default {
  name: 'RangeDate',
  mixins: [commMixin],
  data() {
    return {
      showPicker: false,
      startDate: null,
      endDate: null
    }
  },
  computed: {
    minDate() {
      const date = this.config.minDate || '2000-01-01'
      return dayjs(date).toDate()
    },
    maxDate() {
      const date = this.config.maxDate || '2050-12-31'
      return dayjs(date).toDate()
    },
    endMinDate() {
      let date = this.minDate
      if (this.startDate) {
        date = dayjs(this.startDate).add(1, 'day').toDate() // this.startDate
      }
      return date
    },
    dateFormat() {
      return this.config.dateFormat || 'YYYY-MM-DD HH:mm:ss'
    },
    maxRange() {
      return this.config.maxRange || ''
    },
    dateType() {
      return this.config.dateType || 'date'
    }
  },
  watch: {
    value(val) {
      if (!val || (Array.isArray(this.value) && !this.value.length)) {
        this.startDate = dayjs().toDate()
        this.endDate = dayjs().add(1, 'day').toDate()
      }
    }
  },
  created() {
  },
  mounted() {
    // 回显
    let value = [dayjs().toDate(), dayjs().add(1, 'day').toDate()]
    if (Array.isArray(this.value) && this.value.length === 2) {
      value = this.value
    } else if (Array.isArray(this.config.defaultVal) && this.config.defaultVal.length === 2) {
      value = this.config.defaultVal
    }
    this.startDate = dayjs(value[0]).toDate()
    this.endDate = dayjs(value[1]).toDate()
  },
  beforeDestroy() {
  },
  methods: {
    clickFn() {
      this.showPicker = true
    },
    onClose() {
      if (Array.isArray(this.value) && this.value.length === 2) {
        this.startDate = dayjs(this.value[0]).toDate()
        this.endDate = dayjs(this.value[1]).toDate()
      }
      this.showPicker = false
    },
    onConfirm(value) {
      this.showPicker = false
      this.value = [
        dayjs(value[0]).format(this.dateFormat),
        dayjs(value[1]).format(this.dateFormat)
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.range-date {
  width: 100%;
  padding-bottom: 8px;
  .range-date-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .range-date-value {
    display: inline-block;
    box-sizing: border-box;
    min-width: 154px;
    height: 36px;
    padding: 0 5px;
    font-family: sans-serif, Avenir, Helvetica, Arial;
    line-height: 36px;
    text-align: center;
    background: #F5F5F7;
    border-radius: 4px;
  }
  .range-date-text {
    height: 36px;
    color: #999BA3;
    font-size: 13px;
    line-height: 36px;
  }
  .is-value {
    color: #23252E;
  }
}
</style>
<style lang="scss">
.filter-model-range-date {
  .range-date-pick-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    .cancel {
      padding: 0 16px;
      color: #999;
      font-size: 14px;
    }
    .title {
      color: #333;
      font-size: 16px;
    }
    .confirm {
      padding: 0 16px;
      color: $color-theme;
      font-size: 14px;
    }
  }
  .range-date-pick-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .center {
      padding: 0 5px;
      font-size: 16px;
    }
    .left, .right{
      flex-grow: 1;
    }
  }
}
</style>
