export default {
  props: {
    config: {
      typeof: Object,
      default() {
        return {}
      }
    }
  },
  inject: {
    modelSelf: {
      from: 'filterModelSelf'
    }
  },
  data() {
    return {
      value: ''
    }
  },
  computed: {
    field() {
      return this.config.field
    },
    dataType() {
      return this.config.type
    },
    filterValue() {
      let value = this.config.defaultVal
      if (this.field && (`${this.field}` in this.modelSelf.data)) {
        value = this.modelSelf.data[this.field]
      }
      return value
    },
    dict() {
      return this.modelSelf.dict
    },
    // 处理options
    options() {
      let options = this.config.options
      if (typeof this.config.getOptions === 'function') {
        options = this.config.getOptions(this.dict)
      }
      return options
    }
  },
  watch: {
    // 保证数据一致
    filterValue: {
      immediate: true,
      handler(val) {
        if (typeof val !== 'object') {
          if (val !== this.value) {
            this.value = val
          }
        } else {
          if (JSON.stringify(val) !== JSON.stringify(this.value)) {
            this.value = val
          }
        }
      }
    },
    // 保证数据一致
    value: {
      immediate: true,
      handler(val) {
        this.modelSelf.updated({ [this.field]: val })
      }
    }
  }
}
