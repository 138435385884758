<template>
  <div class="filter-model">
    <filter-popup
      :show="show"
      :height="height"
      :title="title"
      :nodes="nodes"
      :data="data"
      :dict="dict"
      :num="num"
      @update:show="updateShow"
      @update:data="updatedData"
      @update:reset="resetData"
      @update:num="resetNum"
      @confirm="confirm"
    />
    <!-- <filter-calender /> -->
    <!-- <old-select-picker1 v-show="show" /> -->
  </div>
</template>

<script>

// import FilterCalender from './components/filter-calender'
// import OldSelectPicker1 from './components/old-select-picker1'
import FilterPopup from './filter-popup.vue'

export default {
  name: 'FilterModel',
  components: {
    // FilterCalender,
    FilterPopup,
    // OldSelectPicker1,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '80%'
    },
    title: {
      type: String,
      default: '全部筛选'
    },
    nodes: {
      type: Array,
      default() {
        return []
      }
    },
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    dict: {
      type: Object,
      default() {
        return {}
      }
    },
    num: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isShow: false,
      activeNames: []
    }
  },
  computed: {
    valNums() {
      return 0
    }
  },
  provide() {
    return {
      filterContainer: this
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (this.isShow !== val) {
          this.isShow = val
        }
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    updateShow(val) {
      this.$emit('update:show', val)
    },
    updatedData(val) {
      this.$emit('update:data', { ...this.data, ...val })
    },
    resetData() {
      this.$emit('update:data', {})
    },
    resetNum(num) {
      this.$emit('update:num', num)
    },
    confirm() {
      this.$emit('confirm')
    },
    closeModel() {
      this.isShow = false
    }
  }
}
</script>
