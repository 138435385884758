<template>
  <div
    class="go-tree"
    @click.stop="() => {}"
  >
    <tag-items
      :data="tagList"
      @addItem="goTree"
      @closeItem="delItem"
    />
    <van-popup
      v-model="showPicker"
      class="filter-model-range-date"
      :style="{ height: '100%', width: '100%' }"
      position="right"
      :overlay="false"
      :get-container="'body'"
      :close-on-click-overlay="false"
      @click.native.stop="() => {}"
      @close="onClose"
    >
      <org-tree
        :show-form="'popup'"
        :params="params"
      />
    </van-popup>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import orgTree from '@/views/orgTree/tree.vue'
import commMixin from '../../mixins/comm'
import TagItems from '../tag-items'

export default {
  name: 'GoTree',
  components: {
    TagItems,
    orgTree
  },
  mixins: [commMixin],
  data() {
    return {
      showPicker: false,
      tagList: [],
      params: {}
    }
  },
  computed: {
    ...mapGetters('tree', ['filterObj', 'curTreeId', 'timeNum']),
    treeType() {
      return this.config.treeType
    },
    activatedNum() {
      return this.manageSelf.activatedNum
    }
  },
  inject: {
    manageSelf: {
      from: 'manageContainer'
    }
  },
  watch: {
    activatedNum() {
      this.addItem({ ...this.filterObj, curTreeId: this.curTreeId })
    },
    value(val) {
      if (!val) {
        this.tagList = []
      }
    },
    timeNum() {
      this.addItem({ ...this.filterObj, curTreeId: this.curTreeId })
      this.showPicker = false
    }
  },
  beforeDestroy() {},
  methods: {
    ...mapMutations(['SET_KEEPALIVE']),
    ...mapMutations('tree', ['SET_CUR_TREE_ID', 'SET_FILTER']),
    goTree() {
      this.SET_CUR_TREE_ID(this.field)
      this.showPicker = true
      this.params = {
        name: 'tree',
        params: {
          routerName: this.$route.name,
          typeName: this.treeType,
          showForm: 'filter',
          value: this.value
        }
      }
      // this.$loading.show();
      // this.SET_KEEPALIVE([`${this.$route.name}`]);
      // this.$router.push(
      //   {
      //     name: 'tree',
      //     params: {
      //       routerName: this.$route.name,
      //       typeName: this.treeType,
      //       showForm: 'filter',
      //       filterHandle: () => {
      //         clearTimeout(this.loadTimer)
      //         this.showPicker = false
      //         this.$loading.hide();
      //       }
      //     }
      //   },
      // );
      // clearTimeout(this.loadTimer)
      // this.loadTimer = setTimeout(() => {
      //   this.showPicker = false
      //   this.$loading.hide();
      // }, 10000)
    },
    addItem(item) {
      // 单选情况
      if (item.curTreeId === this.field && item[this.treeType] && ('value' in item[this.treeType])) {
        const list = [JSON.parse(JSON.stringify(item[this.treeType]))]
        list[0].text = list[0].name
        this.tagList = list
        this.value = item[this.treeType].value
      }
    },
    delItem() {
      this.value = ''
      this.SET_FILTER({})
    },
    onClose() {
      this.showPicker = false
    }
  }
}
</script>
