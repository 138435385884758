<template>
  <div
    class="single-date"
    @click.stop="() => {}"
  >
    <div
      class="single-date-value"
      :class="{'is-value': value}"
      @click="clickFn"
    >
      {{ value ? value : '请选择' }}
    </div>
    <van-popup
      v-model="showPicker"
      round
      position="bottom"
      class="filter-model-date"
      :get-container="'body'"
      :close-on-click-overlay="false"
      @click.native.stop="() => {}"
    >
      <van-datetime-picker
        v-model="currentDate"
        :type="dateType"
        :title="title"
        :min-date="minDate"
        :max-date="maxDate"
        :confirm-button-text="'确定'"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import commMixin from '../../mixins/comm'

export default {
  name: 'SingleDate',
  mixins: [commMixin],
  data() {
    return {
      showPicker: false,
      currentDate: new Date()
    }
  },
  computed: {
    minDate() {
      const date = this.config.minDate || '2000-01-01'
      return dayjs(date).toDate()
    },
    maxDate() {
      const date = this.config.maxDate || '2050-12-31'
      return dayjs(date).toDate()
    },
    dateFormat() {
      return this.config.dateFormat || 'YYYY-MM-DD'
    },
    dateType() {
      return this.config.dateType || 'date'
    },
    title() {
      let formatStr = ''
      switch (this.dateFormat) {
        case 'YYYY-MM-DD':
          formatStr = 'YYYY[年]MM[月]DD[日]'
          break
        case 'YYYY-MM':
          formatStr = 'YYYY[年]MM[月]'
          break
        case 'YYYY':
          formatStr = 'YYYY'
          break
      }
      let title = dayjs().format(formatStr)
      if (this.currentDate) {
        title = dayjs(this.currentDate).format(formatStr)
      }
      return title
    }
  },
  watch: {
    value(val) {
      if (!val) {
        this.currentDate = new Date()
      }
    }
  },
  created() {
  },
  mounted() {
    if (!this.value && !this.config.defaultVal) {
      this.currentDate = dayjs().toDate()
    } else if (this.value) {
      this.currentDate = dayjs(this.value).toDate()
    } else if (this.config.defaultVal) {
      this.currentDate = dayjs(this.config.defaultVal).toDate()
    }
  },
  beforeDestroy() {
  },
  methods: {
    clickFn() {
      this.showPicker = true
    },
    onClose() {
      if (this.value) this.currentDate = dayjs(this.value).toDate();
      this.showPicker = false
    },
    onConfirm(value) {
      this.showPicker = false
      this.value = dayjs(value).format(this.dateFormat)
    }
  }
}
</script>

<style lang="scss" scoped>
.single-date {
  width: 100%;
  padding-bottom: 8px;
  .single-date-value {
    display: inline-block;
    box-sizing: border-box;
    min-width: 154px;
    height: 36px;
    padding: 0 5px;
    font-family: sans-serif, Avenir, Helvetica, Arial;
    line-height: 36px;
    text-align: center;
    background: #F5F5F7;
    border-radius: 4px;
  }
  .is-value {
    color: #23252E;
  }
}
</style>
<style lang="scss">
.filter-model-date {
  .van-picker__cancel {
    color: #999;
  }
  .van-picker__toolbar {
    color: #333;
  }
  .van-picker__confirm {
    color: $color-theme;
  }
}
</style>
