<template>
  <div
    class="tag-items"
    @click.stop="() => {}"
  >
    <div
      class="tag-item add-icon"
      @click="addItem()"
    >
      <i
        class="icon-joyo"
      >&#xe6f8;</i>
    </div>
    <template v-for="(item, index) in list">
      <div
        v-if="item"
        :key="index"
        class="tag-item"
      >
        <div class="tag-text">{{ item.text }}</div>
        <div class="tag-close">
          <i
            class="icon-joyo"
            @click="closeFn(item, index)"
          >&#xe620;</i>
        </div>
      </div>
      <div
        v-else
        :key="index"
        class="tag-emtry"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'TagItems',
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    list() {
      const list = []
      const len = this.data.length
      const max = len <= 2 ? 2 : (Math.ceil((len - 2) / 3) * 3 + 2)
      for (let i = 0; i < max; i++) {
        list.push(this.data[i])
      }
      return list
    }
  },
  methods: {
    addItem() {
      this.$emit('addItem')
    },
    closeFn(item, index) {
      this.$emit('closeItem', { ...item, index })
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  >div {
    width: calc(33.33% - 5px);
    height: 36px;
    margin-bottom: 8px;
  }
  .tag-item {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    height: 36px;
    padding: 9px 8px;
    line-height: 18px;
    background: #E8FBFF;
    .tag-text {
      flex: 1;
      overflow:hidden;
      color: $color-theme;
      white-space: nowrap;
      text-align: left;
      text-overflow: ellipsis;
    }
    .tag-close {
      width: 12px;
      height: 12px;
      color: $color-theme;
    }
  }
  .add-icon {
    justify-content: center;
    color: #999BA3;
    font-size: 18px;
    background: #F5F5F7;
  }
  .tag-emtry {
    height: 0;
  }
}
</style>

