<template>
  <div class="tag-select">
    <template v-for="(item, index) in list">
      <div
        v-if="!item"
        :key="index"
        class="tag-item tag-empty"
      />
      <div
        v-else-if="!isExpand ? (index < 8) : true"
        :key="index"
        class="tag-item"
        :class="{ multiple: dataType === 'multiple', active: itemValues.includes(`${item.value}`) }"
        @click="clickFn(item)"
      >
        <div>
          {{ item.text }}
        </div>
      </div>
      <div
        v-else-if="!isExpand ? (index === 8) : false"
        :key="index"
        class="tag-item tag-expand"
        @click="expandClick"
      >
        展开
        <i class="icon-joyo">&#xe6f5;</i>
      </div>
    </template>
  </div>
</template>

<script>
import commMixin from '../../mixins/comm'

export default {
  name: 'TagSelect',
  mixins: [
    commMixin
  ],
  props: {},
  data() {
    return {
      isExpand: false
    }
  },
  computed: {
    list() {
      const list = []
      const max = Math.ceil(this.options.length / 3) * 3
      for (let i = 0; i < max; i++) {
        list.push(this.options[i] || '')
      }
      return list
    },
    itemValues() {
      let result
      if (Array.isArray(this.value)) {
        result = this.value.map(item => item)
      } else {
        result = [`${this.value}`]
      }
      return result
    },
    isCollapseExpand() {
      return this.modelSelf.activeNames.includes(this.field)
    }
  },
  watch: {
    isCollapseExpand: {
      immediate: true,
      handler(val) {
        if (!val) {
          this.isExpand = false
        }
      }
    }
  },
  mounted() {
  },
  methods: {
    clickFn(item) {
      if (this.dataType === 'simple') {
        this.simpleSelect(item)
      } else if (this.valueType === 'multiple') {
        this.multipleSelect(item)
      }
    },
    simpleSelect(item) {
      let value = this.value;
      if (value !== item.value) {
        value = item.value
      } else {
        value = ''
      }
      if (JSON.stringify(value) !== JSON.stringify(this.value)) {
        this.value = value
      }
    },
    multipleSelect(item) {
      let value = Array.isArray(this.value) ? JSON.parse(JSON.stringify(this.value)) : []
      if (!value.filter(oldItem => oldItem === item.value).length) {
        value.push(item.value)
      } else {
        value = value.filter(oldItem => oldItem !== item.value)
      }
      if (JSON.stringify(value) !== JSON.stringify(this.value)) {
        this.value = value
      }
    },
    expandClick() {
      this.isExpand = true
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  .tag-item {
    box-sizing: border-box;
    width: calc(33.33% - 5px);
    height: 36px;
    margin-bottom: 8px;
    padding: 0 10px;
    color: #23252E;
    font-family: sans-serif, Avenir, Helvetica, Arial;
    line-height: 36px;
    text-align: center;
    background: #F5F5F7;
    border-radius: 4px;
    transition: all 0.3s;
    >div {
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .tag-expand {
    .icon-joyo {
      display: inline-block;
      color: #999BA3;
      transform: rotate(90deg) translateX(-4px);
    }
  }
  .tag-empty {
    height: 0;
  }
  .tag-item.active {
    color: #00A4FF;
    background: #E8FBFF;
  }
}
</style>
